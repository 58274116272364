import React from "react"
import { Link } from "gatsby"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Slider = ({data, areas}) => {

  return(
    <StyledContainer>
      <h1 className={font.financierMediumItalic + " subtitle"}>{data.subtitle}</h1>
      {data.text.map((data, i)=>(
        <p className={font.nexaRegular + " text"} key={"text" + i}>
          {data}
          <br/>
        </p>
      ))}
      {areas && 
        <>
          <h1 className={font.financierMediumItalic + " subtitle"}>Uma oportunidade para sorrir</h1>
          <p className={font.nexaRegular + " text"}>{data[0]} <span className={font.nexaRegularItalic + " textLink"}><Link to="/clinica#corpo-clinico">{data[1]}</Link></span> {data[2]}</p>
        </>
      }
    </StyledContainer>
  )
}

export default Slider

const StyledContainer = styled.div`
position: relative;
  width: 80%;
  margin: -5vh auto 10vh;
  z-index: 2;
  .subtitle{
    text-align: center;
    color: #e6e6e6;
    font-size: calc(40px + 40 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){font-size: 80px;}
    @media only screen and (max-width: 769px){font-size: 40px;}
  }
  .title, .subtitle, .text{
    margin: 2rem 0;
  }
  .text{
    width: 100%;
    color: #717171;
    line-height: 1.8em; 
    white-space: pre-wrap;
  }
  .textLink{
    text-decoration: underline;
    display: inline-block;
  }
`