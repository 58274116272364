import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import useLockBodyScroll from "../custom/use-lock-body-scroll"
import { Button } from "./buttons"

import Consulta from "./headerConsulta"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import seta from "../images/svg/areaArrowGrey.svg"

const Marcador = ({data}) => {

  const [modal, setModal] = useState(false);
  useLockBodyScroll(modal);

  return (
    <StyledMarcador>
      <CSSTransition in={modal===true} timeout={350} classNames={"switch"} unmountOnExit>
        <Consulta setModal={setModal}/>
      </CSSTransition>
      <Button regular noHover onClick={()=>{setModal(!modal)}} className={font.nexaHeavy + " consulta"}>
        <h5 className="btnTxt">Agende já a sua consulta</h5>
        <img src={seta} alt="seta" className="btnSeta"/>
      </Button>
    </StyledMarcador>
  )
}

export default Marcador

const StyledMarcador = styled.div`
  .consulta{
    border-radius: 10px;
    width: 80%;
    background-color: rgba(8, 203, 239, 0.44);
    padding: 10px 15px;
    margin: auto;
    .btnTxt{
      color: #3b3b3b;
      text-align: center;
      letter-spacing: 0.1em;
      margin: 0 auto;
    }
    .btnSeta{
      position: absolute;
      right: 2vw;
      top: 50%;
      transform: translateY(-50%);
      width: 4vw;
      max-width: 26px;
      img{
        width: 4vw;
        max-width: 26px;
      }
    }
  }

`