import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Header from "../../components/header"
import Intro from "../../components/intro"
import Cards from "../../components/areaCards"
import Testemunhos from "../../components/testemunhos"
import Footer from "../../components/footer"

import HeaderMobile from "../../components/headerMobile"
import ApresentacaoMobile from "../../components/apresentacaoMobile"
import CardsMobile from "../../components/areaCardsMobile"
import Marcador from "../../components/marcador"
import TestemunhosMobile from "../../components/testemunhosMobile"
import FooterMobile from "../../components/footerMobile"

const AreasPage = ({data}) => (
  <Layout>
    <SEO title="Áreas Clínicas" />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile header={data.globalJson.header}/>
        <Intro data={data.areasJson.intro} blueBlack={true}/>
        <ApresentacaoMobile data={data.areasJson.apresentacao} areas={true}/>
        <CardsMobile data={data.areasJson.cardsTop} title="Áreas Clínicas" anchor="areasAnchor"/>
        <Marcador />
        <CardsMobile data={data.areasJson.cardsBottom}/>
        <TestemunhosMobile data={data.globalJson.testemunhos} />
        <FooterMobile data={data.globalJson.footer} />
      </>
      ) : (
      <>
        <Header header={data.globalJson.header}/>
        <Intro data={data.areasJson.intro} blueBlack={true} presentation={data.areasJson.apresentacao}/>
        <Cards data={data.areasJson.cardsTop} title="Áreas Clínicas" anchor="areasAnchor"/>
        <Cards data={data.areasJson.cardsBottom}/>
        <Testemunhos data={data.globalJson.testemunhos} />
        <Footer data={data.globalJson.footer} />
      </>)
    }
  </Layout>
)

export default AreasPage

export const Json = graphql`
  query areasClinicas {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      testemunhos{
        title
        subtitle
        quotes{
          text
          author
        }
      }
      footer{
        logo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
    }
    areasJson {
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      },
      apresentacao,
      cardsTop{
        title
        img{
          publicURL
        }
        link
      }
      cardsBottom{
        title
        img{
          publicURL
        }
        link
      }
    }
  }
`