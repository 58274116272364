import React from "react"
import styled from "styled-components"
import Card from "./card"
import font from "../fonts/fonts.module.scss"

const Cards = ({data, title, anchor}) => {
  return(
    <StyledContainer margin={title==="Especialidades" ? "-15vh auto 180px" : "0 auto"} id={anchor && anchor}>
      <h4 className={font.nexaBold + ' title'}>{title}</h4>
      <div className="list">
        {data.map((data, i)=>(
          <Card data={data} key={i} className="test"/>
        ))}
      </div>
    </StyledContainer>
  )
}


export default Cards

const StyledContainer = styled.div`
  margin: ${props => props.margin};
  position: relative;
  z-index: 3;
  .title{
    width: 70%;
    margin: 0 auto 100px;
    text-transform: uppercase;
    color: #717171;
  }
  .list{
    width: calc(645px + 1515 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){width: 2160px;}
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    grid-row-gap: 8rem;
    margin: 4rem auto;
    > div{
      width: fit-content;
    }
  }
`