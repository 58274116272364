import React from "react"
import { Link } from "gatsby"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Especialidades = ({data}) => {
  return(
    <StyledContainer>
      <Link className="square" to={data.link}>
        <img src={data.img.publicURL} alt="icon"/>
        <hr className="line"/>
        <p className={font.nexaBold + " cardTitle"}>{data.title}</p>
      </Link>
    </StyledContainer>
  )
}

export default Especialidades

const StyledContainer = styled.div`
position: relative;
top: 0;
transition: transform 0.2s;
:hover{
  transform: translate(3px, -10px);
  @media only screen and (max-width: 768px){transform: translate(-1px, 3px);}
}
  .square{
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.16);
    width: calc(145px + 305 * (100vw - 769px) / 1791);
    height: calc(145px + 305 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){width: 450px;height: 450px;}
    @media only screen and (max-width: 768px){width: 145px;height: 145px;}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    img{width: 50%;}
    p{
      text-align: center;
      color: #17d4e3;
      text-transform: uppercase;
      @media only screen and (max-width: 768px){font-size: 0.7em;}
    }
    .line{
      width: 30%;
      border: none;
      margin: 1.5em auto 1.5em;
      border-top: #17d4e3 1px solid;
    }
    .cardTitle{
      height: 20px;
    }
  }
`